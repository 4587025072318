import React from 'react';
import { TextInput, Button, RadioGroup, Radio } from '@mantine/core';
import axios from 'axios';
import { useNotifications } from '@mantine/notifications';

export default function FormPerson(props) {
  const data = props.data;
  const setData = props.setData;
  const notifications = useNotifications();

  const [person, setPerson] = React.useState({
    civility:'',
    firstname: '',
    lastname: '',
    middlename: '',
    email: '',
    phone: '',
    adress: '',
    number_card: ''
  });

  const handleChange = (e) => { 
    setPerson({
      ...person,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (person.civility === '' || person.firstname === '' || person.lastname === '' || person.email === '' || person.phone === '' || person.adress === '' || person.number_card === '') {
      alert('Veuillez remplir tous les champs');
    } else {
      axios.post('https://api.marco-auto.org/gestion/sell-car', { sellCar: data, person: person })
        .then(function (response) {
          notifications.showNotification({
            message: response.data.message,
            type: 'success',
            duration: 3000,
          })
          setData({
            mark: '',
            model: '',
            year_manufacture: '',
            mileage: '',
            town: '',
            township: '',
            register_number: '',
            energy: '',
            boxCar: ''
          });
          setPerson({
            civility: '',
            firstname: '',
            lastname: '',
            middlename: '',
            email: '',
            phone: '',
            adress: '',
            number_card: ''
          });
          props.setStep(!props.step);
        })
        .catch(function (error) {
          notifications.showNotification({
            message: error.response.data.error,
            type: 'error',
            duration: 3000,
          });
        });
    }
  };

  return (
    <div className="formulaire">
      <div className='head-form'>
        <div className="nb"> <span>2</span>
          <h3>Étape 2/2 </h3>
        </div>
        <span className='detail'>Veuillez remplir toutes les informations de votre véhicule dans le formulaire ci-dessous.</span>
      </div>
      <div className='form'>
        <RadioGroup
          label='Civilité : '
          size='sm'
          value={person.civility}
          onChange={(val)=>{setPerson({...person, civility: val})}}
          required
          className='d-inline-flex flex-row  sl-20'>
          <Radio value='Mr'> Mr </Radio>
          <Radio value='Mme'> Mme </Radio>
        </RadioGroup>
        <div className="form-sell-group">
          
          <div className='form-group'>
            <TextInput
              label='Nom'
              size='sm'
              type='text'
              name='lastname'
              value={person.lastname}
              onChange={handleChange}
              placeholder='Matondo'
              required
              className='sell-control' />
          </div>
          <div className='form-group'>
            <TextInput
              label='Post-nom'
              type='text'
              name='middlename'
              value={person.middlename}
              onChange={handleChange}
              placeholder='Solo'
              size='sm'
              required
              className='sell-control' />
          </div>
        </div>
        <div className="form-sell-group">
          <div className='form-group'>
            <TextInput
              label='Prenom'
              placeholder='Erick'
              type='text'
              size='sm'
              name='firstname'
              value={person.firstname}
              onChange={handleChange}
              required
              className='sell-control' />
          </div>
          <div className='form-group'>
            <TextInput
              label='Adresse du domicile'
              placeholder='3 av. pumbu c/ngaliema'
              type='text'
              size='sm'
              name='adress'
              value={person.adress}
              onChange={handleChange}
              required
              className='sell-control' />
          </div>
        </div>
        <div className="form-sell-group">
          <div className='form-group'>
            <TextInput
              label='Numéro de téléphone'
              type='tel'
              placeholder='+243 8100078766'
              size='sm'
              name='phone'
               value={person.phone}
               onChange={handleChange}
                required 
                className='sell-control' />
          </div>
          <div className='form-group'>
            <TextInput 
            label='Email'
             type='email'
              placeholder='jhondoe@yahoo.com' 
              size='sm'
              name='email'
              value={person.email}
              onChange={handleChange}
               required
                className='sell-control' />
          </div>
        </div>
        <div className="form-sell-group">
          <div className="form-group" style={{ marginLeft: 0 }} >
            <TextInput
              label="Numéro de votre de pièce d'identité"
              placeholder='123456789'
              type='text'
              size='sm'
              name='number_card'
              value={person.number_card}
              onChange={handleChange}
              className='w-sell'
              required />
          </div>
        </div>
        <div className='btn-c' >
          <Button
            type='submit'
            variant='gradient'
            gradient={{ from: 'pink', to: 'red' }}
            style={{ width: 200 }}
            disabled={person.civility === '' || person.firstname === '' || person.lastname === '' || person.email === '' || person.phone === '' || person.adress === '' || person.number_card === ''}
            onClick={handleSubmit}
          >Valider</Button>
        </div>
      </div>
    </div>
  )
};