import * as React from "react"
import { motion, useAnimation } from 'framer-motion';
import Layout from "../components/layout";
import { useInView } from "react-intersection-observer";
import Seo from "../components/seo";
import { fadeIn } from "../animation";
import { Carousel } from 'react-bootstrap';
import Lottie from 'lottie-react';
import carAnimation from '../lottie/data.json';
import certified from '../lottie/certified.json';
import price from '../lottie/price.json';
import office from '../lottie/office-worker.json';
//import { AiOutlineIdcard } from 'react-icons/ai';
import { MDBCard, MDBCardBody, MDBCardTitle,  MDBRipple } from 'mdb-react-ui-kit';
import { FaLinkedin } from 'react-icons/fa';

const IndexPage = ({ data }) => {
  const animation = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: "0px"
  });

  


  React.useEffect(() => {
    if (inView) {
      animation.start("animate");
    }
  }, [animation, inView]);

  return (
    <Layout>
      <Seo title="Marco auto" />
      <div className='h-auto w'>
        <motion.div className='w' animate={animation} ref={ref}>
          <Carousel >
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../images/voiture.jpg")}
                alt="photo entete"
              />
              <Carousel.Caption>
                {/*<motion.h2 variants={fadeIn}>Marco auto</motion.h2>
                <motion.p variants={fadeIn} >Achetez et vendez en toute securite.</motion.p>*/}
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../images/berto.png")}
                alt="Second slide"
              />

              <Carousel.Caption>
                <motion.h2 variants={fadeIn} className='text-black' ></motion.h2>
                <motion.p variants={fadeIn} className='text-black' ></motion.p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </motion.div>
      </div>
      <motion.div className='container' ref={ref} >
        <div className='row'>
          <div className='col-welcome'>
            <div className='first-w' data-aos="fade-down-right" data-aos-delay="1000" >
              <motion.h2 variants={fadeIn} >Bienvenu chez Marco auto</motion.h2>
              <motion.p className="text-welcome mt-20">Marco Auto est un réseau de vente et d’achat des véhicules qui développe son concept au travers la ville de Kinshasa afin de favoriser et de sécuriser les opérations y afférentes.
                Pour ce faire Marco Auto travaille au quotidien avec un large réseau de partenaires spécialisés et reconnus en la matière.

              </motion.p>
            </div>
            <div className='welcome-card' data-aos="fade-down-left" >
              <div className='w-card' data-aos="fade-right" >
                <Lottie
                  animationData={carAnimation}
                  loop={false}
                  className='w-img'
                />
                <h6>Depot vente</h6>
              </div>
              <div className='w-card' data-aos="fade-left" >
                <Lottie
                  animationData={certified}
                  loop={false}
                  className='w-img'
                />
                <h6>Occasion , selectionner et garantie</h6>
              
              </div>
              <div className='w-card' data-aos="fade-right" >
                <Lottie
                  animationData={price}
                  loop={false}
                  className='w-img'
                />
                <h6>Vehicule a juste prix</h6>
                
              </div>
              <div className='w-card' data-aos="fade-left"  >
                <Lottie
                  animationData={office}
                  loop={true}
                  className='w-img'
                />
                <h6>Gestion admnistrative</h6>
              
              </div>
            </div>
          </div>
        </div>
        
      </motion.div>

      <motion.div className="container-fluid  bg-red  p-3" ref={ref} animate={animation} data-aos="fade-up" id="buy" >
        <motion.div className="container" ref={ref} animate={animation}   >
          <div className='row mb-30' >
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 order-2 order-xl-1 order-lg-1 order-md-1 order-sm-2 justify-content-space-evenly ' data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1000" >
              <img src={require("../images/screenshot.png" )} width={160} height={310} className='rot' alt='display application' loading="lazy" style={{ objectFit: 'contain' }} />
              <div className='store-img'>
                <a href="https://play.google.com/store/apps/details?id=com.ebedmeleck.Marco_auto" >
                  <img src={require('../images/appstore.jpg')} className='st' style={{ marginLeft: 20 }} alt='playstore marco-auto' />
                </a>
                {/* <a href="https://play.google.com/store/apps/details?id=com.ebedmeleck.Marco_auto" >
                  <img src='../images/store.jpg' className='st' style={{ marginLeft: 30 }}  alt='app-store marco-auto' />
  </a> */}
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 order-1 order-xl-2 order-lg-2 order-md-2 order-sm-1' data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1000" >
              <motion.p className='text-white mt-20' style={{ marginTop: 20 }} >
                C’est pourquoi toutes les annonces relatives à la vente et à l’achat des véhicules sont relayées dans l’application mobile afin de trouver le plus rapidement possible des acheteurs et vendeurs sérieux.

              </motion.p>
            </div>
          </div>
        </motion.div>
      </motion.div>
      
      <div className='service ' data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"  >
        <div className='container '>
          <div className='row justify-content-md-center' >
            <div className='col-md-6 col-sm-6 col-xs-6  mb-3'  >
              <motion.h3 variants={fadeIn} className='text-center hs' >Bertho                                                                      services</motion.h3>
              <div className='line-service-one'></div>
              <div className='line-service-two'></div>
            </div>
          </div>
          <div className='row justify-content-center centered  sl'>
            <div className='col-md-4 col-sm-6 col-xl-4 col-lg-4  mb-2 mt-1'  >
              <MDBCard style={{ maxWidth: '16rem' }}>
                <img src={require('../images/assur.jpg')} className="card-img-top" alt='assurance' />
                <MDBCardBody>
                  <MDBCardTitle>Assurance</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className='col-md-4 col-sm-6 col-xl-4 col-lg-4 mb-2 mt-1' >
              <MDBCard style={{ maxWidth: '16rem' }}>
                <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                  <img src={require('../images/tr.png')} className="card-img-top" alt='mutation' />
                </MDBRipple>
                <MDBCardBody>
                  <MDBCardTitle>Mutation</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className='col-md-4 col-sm-6 col-xl-4 col-lg-4 mb-2 mt-1' >
              <MDBCard style={{ maxWidth: '16rem' }}>
                <img src={require('../images/ac.jpg')} className="card-img-top" alt='accesoires' />
                <MDBCardBody>
                  <MDBCardTitle>Accesoires</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
            
          
          <div className='row justify-content-center centered  mt-2 sl'>
            <div className='col-md-4 col-sm-6 col-xl-4 col-lg-4  mb-2 mt-1'  >
              <MDBCard style={{ maxWidth: '16rem' }}>
                <img src={require('../images/teint.png')} className="card-img-top" alt='teinte-vitre' />
                <MDBCardBody>
                  <MDBCardTitle>Teinte vitre</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className='col-md-4 col-sm-6 col-xl-4 col-lg-4 mb-2 mt-1' >
              <MDBCard style={{ maxWidth: '16rem' }}>
                <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                  <img src={require('../images/garnissage.jpg')} className="card-img-top" alt='garnissage' />
                </MDBRipple>
                <MDBCardBody>
                  <MDBCardTitle>Garnissage</MDBCardTitle>
                  
                </MDBCardBody>
              </MDBCard>
            </div>
            <div className='col-md-4 col-sm-6 col-xl-4 col-lg-4 mb-2 mt-1' >
              <MDBCard style={{ maxWidth: '16rem' }}>
                <img src={require('../images/key.jpg')} className="card-img-top" alt='repoduction_cles' />
                <MDBCardBody>
                  <MDBCardTitle>Reproduction des clés et initialisation</MDBCardTitle>
                </MDBCardBody>
              </MDBCard>
            </div>
          </div>
          
        </div>
      </div>

      <div className="container"  >
        <div className="row justify-content-md-center"  >
          <div className='col-md-6 col-sm-6 col-xs-6  mb-30 mt-20  p-10'  >
            <motion.h3 className='text-center text-uppercase title ' style={{ marginTop: 20 }} >Leadership</motion.h3>
          </div>
          <p>&nbsp;</p>
        </div>
        <div className="row justify-content-md-center"  >
          <div className='card-leader' data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1300" >
            <div className="social-ceo">
              <div className='ceo'>
                <img src={require('../images/cedric.png')} className="l-img" alt='cedric kiyimpu' />
                <div className="t-ceo" >
                  <h5>Cédric Kiyimpu</h5>
                  <div className='ic-ceo'  >
                    <span className='text-bold' >Chief executive officer</span>
                    <a href='https://www.linkedin.com/in/c%C3%A9dric-kiyimpu-823a42141/' >
                      <FaLinkedin size={30} className='fa' />
                    </a>
                  </div>
                </div>
              </div>
              <div className='p-ceo' >
                <p>  <q>  Avec son expérience dans le secteur de l’automobile, Marco Auto est toujours à la recherche de la perfection des prestations.
                  Son engagement dans le travail, repose dans la satisfaction de sa clientèle. Pour preuve, Marco Auto, dispose de Bertho services pour certaines prestations de service à savoir :	Mutation & Immatriculation , assurance ,	gadget et accessoire intérieur,	tapisserie et garnissage	, location voiture
                  dépannage ,	car wash  . </q>
                </p>
              </div>
              
            </div>
            
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>
      
    </Layout>
  );
};
export default IndexPage
