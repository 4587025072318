import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { privacyHtml } from "../components/privacy"

const Privacy = () => {
  return (
    <Layout>
      <Seo title="Politique et Confidentialite" />
      <div dangerouslySetInnerHTML={{ __html: privacyHtml }} className="container" />
    </Layout>
  );
};

export default Privacy;