import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./routes";

function App() {
  let AOS;

  React.useEffect(()=>{
    const AOS = require('aos');
    AOS.init({
      once:true
    })
  },[]);

  React.useEffect(()=>{
    if(AOS){
      AOS.refresh();
    }
  });
  return (
    <RouterProvider router={router} />
  );
}

export default App;
