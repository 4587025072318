import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import { Link } from 'react-router-dom';
import BuyImage from '../images/buy.jpg';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Buy = () => {
  return (
    <Layout>
      <Seo title="Achetez votre vehicule" />
      <section
        id="page-banner"
        style={{ backgroundImage: `url(${BuyImage})` }}
        class="pt-200 pb-35 bg_cover w-100"
      >
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="banner-content">
                <h3>Achetez</h3>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to='/' >Acceuil</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Achetez</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className='mt-50'
        style={{marginTop:20}}
      >
        <div class="container">
          <div class="row  justify-content-center centered">
            <h3 className='text-center'>Acheter votre vehicule chez Marco Auto</h3>
          </div>

        
        </div>
    </section>
    </Layout>
  );
};

export default Buy;