import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import { Link } from 'react-router-dom';
import BuyImage from '../images/buy.jpg';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Button } from '@mantine/core';
import axios from 'axios';
//import { useNotifications, NotificationsProvider } from '@mantine/notifications';

const Contact = () => {
  const [contact, setContact] = React.useState({
    firstname_contact: '',
    email_contact: '',
    phone_contact: '',
    content_contact: '',
    objet:''
  });
 //const notifications = useNotifications();

  const handleChange = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (contact.firstname_contact && contact.email_contact && contact.phone_contact && contact.content_contact) {
      axios.post('https://api.marco-auto.org/contact/add', contact)
        .then(function (response) {
          alert(response.data.message);
          setContact({
            content_contact: '',
            email_contact:'',
            firstname_contact: '',
            phone_contact: '',
            objet:''
          })
        }).catch((e) => {
          alert(e.response.data.error);
        });
      
    } else {
      
    }
  };



  return (
    <Layout>
      <Seo title="Contactez-nous" />
        <section
          id="page-banner"
          style={{ backgroundImage: `url(${BuyImage})` }}
          class="pt-200 pb-35 bg_cover w-100"
        >
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="banner-content">
                  <h3>Nous contacter</h3>
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to='/' >Acceuil</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Contact</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact-content">
          <div class="container">
            <div class="row justify-content-md-center centered">

              <div style={{ marginTop: 20 }} className="col-md-6 col-sm-6 col-xs-6  mb-30 mt-20  p-10">
                <h2>Nous écrire</h2>
                <form >
                  <div class="form-group">
                    <label htmlFor="name">Nom </label>
                    <input
                      value={contact.firstname_contact}
                      onChange={handleChange}
                      type="text"
                      id="name"
                      class="form-control"
                      required
                      placeholder='Matondo jospin'
                    />
                  </div>
                
                  <div class="form-group">
                    <label htmlFor="number">Téléphone</label>
                    <input
                      value={contact.phone_contact}
                      onChange={handleChange}
                      type="tel"
                      id="number"
                      class="form-control"
                      required
                      placeholder='+243 810000067'
                    />
                  </div>

                  <div class="form-group">
                    <label htmlFor="email">Adresse email</label>
                    <input
                      value={contact.email_contact}
                      onChange={handleChange}
                      type="email"
                      id="email"
                      class="form-control"
                      required
                      placeholder='jhondoe@gmail.com'
                    />
                  </div>

                  <div class="form-group">
                    <label htmlFor="objet">Objet</label>
                    <input
                      value={contact.objet}
                      onChange={handleChange}
                      type="text"
                      id="objet"
                      class="form-control"
                      formControlName="objet"
                      required
                      placeholder='objet'
                    />
                  </div>

                  <div class="form-group">
                    <label htmlFor="content"  >Message</label>
                    <textarea
                      value={contact.content_contact}
                      onChange={handleChange}
                      placeholder='votre message'
                      id="content"
                      cols="30"
                      rows="7"
                      class="form-control"
                      required
                    ></textarea>
                  </div>

                  <div className="row justify-content-md-center centered" >
                    <div className="col-md-6 col-sm-6 col-xs-6  mb-30 mt-20  p-10">
                      <Button
                        variant='gradient'
                        gradient={{ from: 'pink', to: 'red' }}
                        style={{ width: 200, marginTop: 20 }}
                        type='submit'
                        disabled={contact.firstname_contact === '' || contact.phone_contact === '' || contact.email_contact === '' ||
                          contact.objet === '' || contact.content_contact === ''
                        }
                        onSubmit={handleSubmit}
                      >
                        Envoyer
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
    </Layout>
  );
};

export default Contact;