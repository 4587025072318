import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from 'react-router-dom';
import Image404 from '../images/404.jpg'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
      <div className="row justify-content-center centered p-t-30 m-b-20">
        <div className="col-sm-6 col-md-6 col-lg-6 col-xs-6 ">
          <img
            loading="lazy"
            src={Image404}
            style={{ display: 'block', width: 400, height: 300 }}
            alt="notfound" />
        </div>
      </div>
      <div className="row justify-content-center p-t-30 m-b-20">
        <div className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
          <h1>Cette page n'existe pas</h1>
          <p>
            La page que vous recherchez semble introuvable. Sans détour, il n'est
            point de voyage... Retournez à la page d'acceuil
          </p>
          <button className="btn btn-primary"  style={{marginBottom:30}} >
            <Link to="/" style={{ color: 'white', textDecoration: 'none' }}  > Retour à la maison</Link>
          </button>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
