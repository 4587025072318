/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { NotificationsProvider } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';
import Footer from "./footer"
import Header from "./header"
import './layout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import './layout.scss';
import axios from 'axios';
axios.defaults.baseURL = 'https://app.marco-auto.org';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/x-form-urlencoded';

const Layout = ({ children }) => {
  return (
    <MantineProvider>
      <NotificationsProvider limit={5} autoClose={4000} position='top-right' transitionDuration={250}>
        <Header siteTitle={'Marco auto' || `Title`} />
        <main>{children}</main>
        <Footer />
      </NotificationsProvider>
    </MantineProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
