import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Navbar, Nav, Container, } from 'react-bootstrap';
import logo from "../images/logoo-white.png";
import './layout.css';

const Header = ({ siteTitle }) => (
  <Navbar collapseOnSelect expand="lg" bg="dark" >
    <Container className="mt-2 " >
      <Navbar.Brand  >
        <Link to='/' >
          <img
            src={logo}
            width={150}
            height={50}
            className="d-inline-block align-center"
            alt="Marco auto logo"
            style={{ opacity: 1, objectFit: "cover" }}
            sizes="(min-width: 150px) 150px, 100vw"
            loading="lazy"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav"  />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="head-link" >
        <Nav.Link className="text-white" >
            <Link to="/">Acceuil</Link>
          </Nav.Link>
          <Nav.Link className="text-white" >
            <Link to="/sell">Vendez</Link>
          </Nav.Link>
          <Nav.Link className="text-white" href='#buy'  >
            Achetez
          </Nav.Link>
          <Nav.Link className="text-white" >
            <Link to='/contact' >Contact</Link>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
