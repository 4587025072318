import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import './layout.css';
import { FaPhoneAlt, FaEnvelope, FaFacebook, FaHome, FaLinkedin } from 'react-icons/fa';

export default function Footer() {
  return (
    <MDBFooter bgColor='dark' className='text-white text-center text-lg-left'>
      <MDBContainer className='p-1'>
        <MDBRow>
          <MDBCol lg='5' md='12' className='mb-6 mb-md-0'>
            <img
              src={require("../images/logoo-white.png")}
              width={180}
              height={70}
              style={{objectFit: 'cover'}}
              alt='logo marco auto'
            />
            <div className='home-icon' >
              <div className='f-icon' >
                <FaPhoneAlt color='white' /> <span className='number' >+243 812205928</span>
              </div>
              <div className='f-icon' >
                <FaEnvelope color='white' /><a href='mailto:contact@marco-auto.org'>contact@marco-auto.org</a>
              </div>
              <div className='f-icon d-flex align-items-start gap-0' >
                <FaHome color='white'  size={33}/> <span className='ms-1'> 3 av. Pumbu UPN, commune de ngaliema </span>
              </div>
            </div>
          
          </MDBCol>

          <MDBCol lg='2' md='4' className='mb-4 mb-md-0 mt-4 menu'>
            {/*<ul className='list-unstyled mb-0'>
              <li>
                <Link to='/sell' className='text-white' >Vendez</Link>
              </li>
              <li>
                <Link to='/buy' className='text-white'>Acheter</Link>
              </li>
              <li>
                <Link to='/service' className='text-white'>
                  Service
                </Link>
              </li>
              <li>
                <Link to='/contact' className='text-white'>
                  Contact
                </Link>
              </li>
              <li>
                <Link to='/about' className='text-white'>
                  A propos
                </Link>
              </li>
            </ul>*/}
          </MDBCol>

          <MDBCol lg='3' md='6' className='mb-4 mb-md-0  mt-4'>
            <div className='link-social' >
              <h6 className='text-uppercase mb-0'>Nous suivre  : </h6>

              <ul className='list-unstyled mt-2 '>
                <li>
                  <a href='https://facebook.com/marcoauto12' className='text-white'>
                    <FaFacebook color='#139cf8' size={30} />
                  </a>
                </li>
                <li>
                  <a href='https://www.linkedin.com/company/ets-marco' className='text-white' rel='nofollow' >
                    <FaLinkedin color='#0a66c2' size={30} />
                  </a>
                </li>
              </ul>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        &copy; {new Date().getFullYear()}  Marco auto .{' '}
      
        <span className='ml-4 text-white'  >Réaliser par </span>
        <a href="mailto:ebedmeleckmakoso@gmail.com" className='text-blue' >Ebed meleck</a>
      </div>
    </MDBFooter>
  );
};