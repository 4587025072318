import { createBrowserRouter } from "react-router-dom";
import Home from '../pages';
import Buy from "../pages/buy";
import Contact from "../pages/contact";
import Privacy from "../pages/privacy";
import Sell from "../pages/sell";
import Service from "../pages/service";
import FourByFour from "../pages/404";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <FourByFour />
  },
  {
    path: '/buy',
    element: <Buy />,
    errorElement: <FourByFour />
  },
  {
    path: '/contact',
    element: <Contact />,
    errorElement: <FourByFour />
  },
  {
    path: '/sell',
    element: <Sell />,
    errorElement: <FourByFour />
  },
  {
    path: '/privacy',
    element: <Privacy />,
    errorElement: <FourByFour />
  },
  {
    path: '*',
    element: <FourByFour />
  }
]);

export default router;