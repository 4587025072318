import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { TextInput, Button, NumberInput, Select } from '@mantine/core';
import FormPerson from "../components/FomPerson";
import { motion } from "framer-motion";
import { energy, boxcar, year } from "../components/data";

const Sell = () => {
  const [step, setStep] = React.useState(true);
  

  const [data, setData] = React.useState({
    mark: '',
    model: '',
    engine: '',
    boxCar: '',
    registration_number: '',
    year_manufacture: '',
    mileage: '',
    township: '',
    town: '',
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };


  const handleSumbit = (e) => {
    e.preventDefault();
    if(data.mark && data.model && data.engine && data.boxCar && data.registration_number && data.year_manufacture && data.mileage && data.township && data.town){
      setStep(false);
    }
   }

  return (
    <Layout>
      <Seo title="Vendez votre vehicule" />
      <div className="sell-form">
        {step ? (
          <div className="formulaire">
            <div className='head-form'>
              <div className="nb"> <span>1</span>
                <h3>Étape 1/2 : Caractéristiques du véhicule</h3>
              </div>
              <span className='detail'>Veuillez remplir toutes les informations de votre véhicule dans le formulaire ci-dessous.</span>
            </div>
            <div className='form'>
              <div className="form-sell-group">
                <div className='form-group'>
                  <TextInput
                    label='Marque'
                    placeholder="Toyota"
                    type='text'
                    size='sm'
                    required
                    name="mark"
                    value={data.mark}
                    onChange={handleChange}
                    className='sell-control' />
                </div>
                <div className='form-group'>
                  <TextInput
                    type="text"
                    placeholder="Fortuner"
                    value={data.model}
                    label='Modele'
                    size='sm'
                    name="model"
                    required
                    onChange={handleChange}
                    className='sell-control' />
                </div>
              </div>
              <div className="form-sell-group">
                <div className='form-group'>
                  <Select
                   type='text'
                    data={year}
                    label='Annee de fabrication'
                    placeholder="Choisir une année"
                    size='sm'
                    required
                    name='year_manufacture'
                    value={data.year_manufacture}
                    onChange={(e)=>setData({...data, year_manufacture: e})}
                    className='sell-control' />
                </div>
                <div className='form-group'>
                  <TextInput
                    value={data.registration_number}
                    label='Immatriculation'
                    size='sm'
                    name='registration_number'
                    required
                    onChange={handleChange}
                    className='sell-control' />
                </div>
              </div>
              <div className="form-sell-group">
                <div className='form-group'>
                  <Select
                    data={energy}
                    placeholder='Choisir votre energie'
                    label='Energie'
                    size='sm'
                    required
                    value={data.engine}
                    name='engine'
                    onChange={(e)=>setData({...data, engine: e})}
                    className='sell-control' />
                </div>
                <div className='form-group'>
                  <Select
                    data={boxcar}
                    placeholder='Choisir votre boite de vitesse'
                    label='Boite de vitesse'
                    size='sm'
                    required
                    value={data.boxCar}
                    name='boxCar'
                    onChange={(e)=>setData({...data, boxCar: e})}
                    className='sell-control' />
                </div>
              </div>
              <div className="form-sell-group">
                <div className="form-group" style={{ marginLeft: 0 }} >
                  <NumberInput
                    label='Kilometrages'
                    placeholder='45000'
                    value={data.mileage}
                    name='mileage'
                    onChange={(e)=>setData({...data, mileage: e})}
                    size='sm'
                    required
                    className='w-sell' />
                </div>
              </div>
              <div className="form-sell-group">
                <div className='form-group'>
                  <TextInput
                    type='text'
                    label='Commune'
                    size='sm'
                    name='township'
                    required
                    value={data.township}
                    onChange={handleChange}
                    className='sell-control' />
                </div>
                <div className='form-group'>
                  <TextInput
                    type='text'
                    name='town'
                    value={data.town}
                    onChange={handleChange}
                    label='Ville'
                    className='sell-control'
                    size='sm'
                    required />
                </div>
              </div>
              <div className='btn-c' >
                <Button
                  variant='gradient'
                  gradient={{ from: 'pink', to: 'red' }}
                  style={{ width: 200 }}
                  disabled={data.mark === '' || data.model === '' || data.engine === '' || data.boxCar === '' || data.registration_number === '' || data.year_manufacture === '' || data.mileage === '' || data.township === '' || data.town === ''}
                  onClick={handleSumbit}
                  >Suivant</Button>
              </div>
            </div>
          </div>) : (
          <FormPerson step={step} setStep={setStep} data={data} setData={setData} />)}
      </div>

      <div className="container"  >
        <div className="row justify-content-md-center"  >
          <div className='col-md-6 col-sm-6 col-xs-6  mb-30 mt-20  p-10'  >
            <motion.h4 className='text-center text-uppercase title ' style={{marginTop:20}} >les étapes de la mise en vente/ les avantage</motion.h4>
          </div>
        </div>
        <div className="row">
          <div className='col-12'>
            <div className="content-page">
              <p>
                <img
                  src={require("../images/step-1.png")}
                  alt='step-1'
                  height={64}
                  width={64}
                  style={{ float: 'left' }}
                />
              </p>
              <h4 style={{ marginLeft: 120 }}>
                <span style={{ color: '#DB2D2E' }} >Prise de contact</span>
              </h4>
              <ul style={{ marginLeft: 120 }} className="sell-ul" >
                <li>Remplisser le formulaire répris ci-haut</li>
                <li>Nous vous contacterons dans les plus brefs délais</li>
                <li>Apres verification, nous effectuons un reportage complet(10 photos maximum)</li>
                <li>Nous convenons ensemble du prix de vente</li>
              </ul>

              <p>
                <img
                  src={require("../images/step-2.png")}
                  alt='step-2'
                  height={64}
                  width={64}
                  style={{ float: 'left' }}
                />
              </p>
              <h4 style={{ marginLeft: 120 }}>
                <span style={{ color: '#DB2D2E' }} >Mise en vente</span>
              </h4>
              <ul style={{ marginLeft: 120 }} className="sell-ul" >
                <li>Nous diffusons l'annonce de votre vehicule sur nos reseaux , partenaire spécialisés et reconnus</li>
                <li>L'annonce est ainsi relayées dans notre application mobile avec tout les détails.</li>
                <li>Nous gerons les appels , negociations, visite et essaies .</li>
              </ul>
              <p>&nbsp;</p>
              <hr />
              <h4 className="text-center">
                <span style={{ color: '#DB2D2E' }} >Les avantages de la vente via Marco Auto</span>
              </h4>
              <ul>
                <li>Chez Marco Auto, nous nous engageons ainsi à vendre votre véhicule au tarif le plus juste</li>
                <li>Nous nous chargeons entièrement de la charge administrative liée à cette opération (certificat de non-gage, déclaration de la cession, démarches de carte rose, etc.)</li>
                <li>Afin d’annihiler tout risque d’arnaque.  Notre crédo : vendre votre voiture d’occasion en toute sécurité.</li>

              </ul>
              <p>&nbsp;</p>

            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Sell;
